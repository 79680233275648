import React, { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography, Button, Tooltip } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import clsx from "clsx";
import { useStyles } from "./styles";

import PropTypes from "prop-types";
import { useEnqueueSnackbar } from "../../../../hooks/useEnqueueSnackbar";

import useCancellationToken from "../../../../hooks/useCancellationToken";
import apiClient from "../../../../auth/apiClient";

export const UpdateFacilityInfo = ({ entityId }) => {
  const classes = useStyles();
  const enqueueSnackbar = useEnqueueSnackbar();

  const [expanded, setExpanded] = useState(true);

  const toggleAccordion = () => {
    setExpanded(!expanded);
  };

  const { execute: updateInfo, inProgess } = useCancellationToken({
    func: async function ({ deviceID, cancelToken }) {
      await apiClient.put(`device/admin/changefacility/${deviceID}`, {
        headers: { "Content-Type": "application/json" },
        cancelToken: cancelToken
      })
    }, errHandleFunc: (err) => {
      enqueueSnackbar('Failed to Request Update Facility Information' + err, {
        variant: "error", tag: "FailedToUpdateFacilityInfo"
      });
    }
  });

  const handleUpdateInfoClick = () => {
    updateInfo({ deviceID: entityId });
  };

  return (
    <Grid
      container spacing={2}
      className={clsx("facility-panel", classes.panelRoot)}
      style={{ paddingTop: 10, marginBottom: 10, width: "100%" }}
    >
      <Accordion
        className={clsx("accordion", classes.fullWidthAccordion)}
        expanded={expanded}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={toggleAccordion}
        >
          <Typography className={clsx("heading", classes.heading)}>
            Devices
          </Typography>
        </AccordionSummary>

        <AccordionDetails>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>

              <Tooltip
                arrow
                placement="right"
                title="Send the latest facility ID to the device.  The device must be connected."
              >

                <Button
                  className={clsx(["btn-updated-facility"])}
                  fullWidth
                  variant="contained"
                  data-testid="update-facility-btn"
                  onClick={handleUpdateInfoClick}
                  color="primary"
                  disabled={inProgess}
                >
                  Update Facility Info
                </Button>

              </Tooltip>

            </Grid>
          </Grid>

        </AccordionDetails>

      </Accordion>
    </Grid>
  );
};

UpdateFacilityInfo.defaultProps = {
  entityId: PropTypes.string,
};

UpdateFacilityInfo.propTypes = {
  entityId: PropTypes.string,
};

export default UpdateFacilityInfo;
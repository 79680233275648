import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  panelRoot: {
    marginBottom: theme.spacing(2),
    "@media print": {
      display: "none",
    },
  },
  fullWidthAccordion: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    flexBasis: "33.33%",
    flexShrink: 0,
    color: theme.palette.primary.main,
  },
}));